
import React from 'react';
import '../css/Stylesheet.css'



function InputBoxInt(props){
  return (
    <form onSubmit={props.handleSubmit}>
      <label>{props.fieldName}:</label><br />
        <input 
        className="textBox"
        type="number"
        step = "1"
        min = "18"
        max = "90" 
        value={props.value} 
        onChange={props.handleChange} />
    </form>
  );
}

export default InputBoxInt;
