import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './js/App';
import reportWebVitals from './js/reportWebVitals';
import {configureAmplify} from './services'

import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

//configureAmplify()

ReactDOM.render(
  // ReactDOM.render takes react elements and passes it to the root DOM note (from html)
  <App defaultValue={process.env}/>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results 
// (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();