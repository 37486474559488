
import React from 'react';
import '../css/Stylesheet.css'



function InputBox(props){
  return (
    <form onSubmit={props.handleSubmit}>
      <label>{props.fieldName}:</label><br />
        <input 
        className="textBox"
        type="number"
        step = "0.01"
        min = "0"
        max = "100" 
        value={props.value} 
        onChange={props.handleChange} />
    </form>
  );
}

export default InputBox;
