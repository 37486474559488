
import React from 'react';
import '../css/Stylesheet.css'


function ImageGuide(props){
  return (
    <img src={props.src} class = "imageGuide" alt = {props.alt}></img>
  );
}

export default ImageGuide

