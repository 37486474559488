
import React from 'react';
import '../css/Stylesheet.css'



function Agreement(props){
  return (
<div className="mainPage">
  <h2>Our Agreement</h2>
  <p>Since at least some of this data may be considered sensitive, I would like to make a simple agreement 
    to show you that I will treat your information with respect.</p>
  <p>I want to keep the agreement simple, with no fine print or confusing terms. 
    Basically, it's your data, and you give me permission to use it. You reserve 
    the right to change your mind at any time, and I will respect that. </p>
  <ol>
    <li>The data you provide is yours (participant), and you can request me to delete it at any point.</li>
    <li>The data will only be used for model training/testing, and nothing else. </li>
    <li>The data will be encrytped and stored in a secure database with no public access.</li>
    <li>Your face will be blurred in the database. I have an automatic step that blurs your face as the image gets uploaded. Ask me to confirm anytime, no trouble at all.</li>
    <li>Your name will not be directly linked to your data, I will assign a generic ID for your record. To honor my agreement, 
      I will need to find your data in case you want me to delete it. I will have a separate log (independent of the database) that will have your name and the correspondign data id.
      Separating the two adds an additional layer of security and preserves your privacy.</li>
    <li>Feel free to ask for clarifications or proof of anything I claimed above. I will maintain full transparency and want to easy your mind if I can. </li>
    <li>You can add other points to the agreement if you wish to, and I will be happy to comply if it's reasonable. </li>
  </ol>
  <p>email: dennismdan@gmail.com</p>
</div>
  );
}

export default Agreement;
