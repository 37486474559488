
import React from 'react';
import '../css/Stylesheet.css'

function GenderSelector(props){
  return (
    <form>
    <div className="radio" onChange={props.handleChangeGender}>
    <label>{props.fieldName}:</label><br />
      <label>
        <input type="radio" 
              value="female" 
              checked={props.selectedOption === 'female'} 
              onClick={props.handleChangeGender} />
        Female
      </label>
    </div>
    <div className="radio">
      <label>
        <input type="radio" 
              value="male" 
             checked={props.selectedOption === 'male'} 
             onClick={props.handleChangeGender} />
        Male
      </label>
    </div>
    <div className="radio">
      <label>
        <input type="radio" 
              value="other" 
              checked={props.selectedOption === 'other'} 
              onClick={props.handleChangeGender} />
        Other
      </label>
    </div>
  </form>
  );
  }

export default GenderSelector;
