import * as JSZip from 'jszip';
import FileSaver from 'file-saver';
import '../App.css';
import Inputbox from './Inputbox';
import React from 'react';
import ImageInput from './ImageInput';
import GenderSelector from './GenderSelector'
import ImageGuide from './ImageGuide'
import InputBoxText from './InputboxText'
import InputBoxInt from './InputboxInt'
import Agreement from './Agreement'
import frontViewImagePath from '../images/silhuetteFront.png'
import sideViewImagePath from '../images/silhuetteSide.png'
import '../css/Stylesheet.css'
import { render } from 'react-dom';
//import Storage from '@aws-amplify/storage';
import Amplify, { Storage } from 'aws-amplify';
import awsconfig from '../aws-exports';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner"


Amplify.configure(awsconfig);

// TODO: final sheet formatting is off and doesn't fit screen properly
      
var measurementGuide = {
  "male":["/../images/male.jpg",
          "/../images/shoulder-male.PNG",
          "/../images/arm-male.PNG",
          "/../images/neck-male.PNG",
          "/../images/chest-male2.PNG",
          "/../images/waist-male.PNG",
          "/../images/feet-male.PNG"],
  "female":["/../images/female.jpg",
            "/../images/shoulder-female.PNG",
            "/../images/arm-female.PNG",
            "/../images/neck-female.PNG",
            "/../images/chest-female.PNG",
            "/../images/waist-female.PNG",
            "/../images/feet-female.PNG"]
};

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      displayImageFront: frontViewImagePath,
      displayImageFrontObj: '',
      displayImageSide: sideViewImagePath,
      displayImageSideObj: '',
      loadState: false,
      name: '',
      gender: 'female',
      imagGuide: "female",
      age: 0,
      height: 0,
      shoulder:0,
      sleeve:0,
      neck:0,
      chest:0,
      waist:0,
      legs:0,
      doneSubmit: false,
      submitSuccess: false

    };

    this.handleChangeImageInputFront = this.handleChangeImageInputFront.bind(this);
    this.handleChangeImageInputSide = this.handleChangeImageInputSide.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeGender = this.handleChangeGender.bind(this);

    this.handleChangeAge = this.handleChangeAge.bind(this);
    this.handleChangeHeight = this.handleChangeHeight.bind(this);
    this.handleChangeShoulder = this.handleChangeShoulder.bind(this);
    this.handleChangeSleeve = this.handleChangeSleeve.bind(this);
    this.handleChangeNeck = this.handleChangeNeck.bind(this);
    this.handleChangeChest = this.handleChangeChest.bind(this);
    this.handleChangeWaist = this.handleChangeWaist.bind(this);
    this.handleChangeLegs = this.handleChangeLegs.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
  }


  handleChangeImageInputFront(event) {
    this.setState({
      displayImageFront: URL.createObjectURL(event.target.files[0]), 
      displayImageFrontObj: event.target.files[0]});
  }

  handleChangeImageInputSide(event) {
    this.setState({
      displayImageSide: URL.createObjectURL(event.target.files[0]),
      displayImageSideObj: event.target.files[0]});
  }
  
  handleChangeName(event) {
    this.setState({name: event.target.value});
  }

  handleChangeGender(event) {
    var newMeasurementImages = event.target.value==="male"?  "male": "female";
    this.setState({gender: event.target.value});
    this.setState({imagGuide: newMeasurementImages});
  }
  
  handleChangeAge(event) {
    this.setState({age: event.target.value});
  }

  handleChangeHeight(event) {
    this.setState({height: event.target.value});
  }

  handleChangeShoulder(event) {
    this.setState({shoulder: event.target.value});
  }

  handleChangeSleeve(event) {
    this.setState({sleeve: event.target.value});
  }

  handleChangeNeck(event) {
    this.setState({neck: event.target.value});
  }

  handleChangeChest(event) {
    this.setState({chest: event.target.value});
  }

  handleChangeWaist(event) {
    this.setState({waist: event.target.value});
  }
  handleChangeLegs(event) {
    this.setState({legs: event.target.value});
  }

  getDateTime(){
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds();
    var dateTime = date+'_'+time;
    return dateTime
  }

  async generateZip(ImageSide,ImageFront){
    var zip = new JSZip();
    zip.file("front-image.jpeg",ImageFront, {base64: true});
    zip.file("side-image.jpeg",ImageSide, {base64: true});
    zip.file("measurements.json", JSON.stringify(this.state));
    return zip;
  }

  async loadDataToS3(s3Key,ImageSide,ImageFront){ 
    
    var loadStatus = true;
    var s3key = `${s3Key}/user-data.zip`;
    var zip = await this.generateZip(ImageSide,ImageFront);
    
    await zip.generateAsync({type:"blob"}).then(
      async function(zipFile) {
        //FileSaver.saveAs(zipFile, "example.zip");
        await Storage.put(s3key, zipFile).then(async value => {
          console.log(value); // Success!
        }, async reason => {
          console.log('Error uploading file:', reason);// Error!
          loadStatus = false;
        })
      }
    );
    return loadStatus
  }

  async checkImageStatus(ImageFront,ImageSide){

    var status = 0
    
    if (typeof ImageFront === 'string' || ImageFront instanceof String) {
      alert("Missing front image");
      ++status
    } 
  
    if (typeof ImageSide === 'string' || ImageSide instanceof String) {
      alert("Missing side image");
      ++status
    };

    return status;

  }
  
  updateDataUploadState(dataUploaded){

    if(dataUploaded){
      this.setState({doneSubmit: true, submitSuccess: true})
      console.log(`dataUploaded ${dataUploaded}`)
    } else {
      this.setState({doneSubmit: true, submitSuccess: false})
      console.log(`dataUploaded ${dataUploaded} `)
    }
  }

  async checkDataInputs(){
    var checkValues = ["age","height","shoulder","sleeve","neck","chest","waist","legs"];
    var componsnet = this; 
    var unfilledInputFields = []
    checkValues.forEach(function (item, index) {
      
      var componentValue = componsnet.state[item]

      if (componentValue==0){
        unfilledInputFields.push(item);
      }
    });
    
    if (unfilledInputFields.length){
      await alert("Form fields that need to be filled out: "+unfilledInputFields.join());
      return false; // some fields were not filled out return false 
    } else {
      return true; // all fields were filled out
    }

    
  }

  async handleSubmit(){
    this.setState({loadState: true})

    console.log("Submit data")
    var ImageSide = this.state.displayImageSideObj
    var ImageFront = this.state.displayImageFrontObj

    var imgStatus = await this.checkImageStatus(ImageFront,ImageSide)
    if (!imgStatus){
      console.log("Added both images")
    } else {
      this.setState({loadState: false})
      return 
    }

    var allFieldsFilledOut = await this.checkDataInputs()

    if (allFieldsFilledOut){
      console.log("User filled all critical fields.")
    } else { 
      console.log("User left empty fields.")
      this.setState({loadState: false})
      return
    }

    let todaysdateTime = this.getDateTime()
    var s3Key = `data/${todaysdateTime}`

    try {
      var dataUploaded = await this.loadDataToS3(s3Key,ImageSide,ImageFront);
      
      console.log("returned message from upload:")
      console.log(dataUploaded)

    } catch (err) {
      console.log("returned err from upload:")
      console.log(err)
      var dataUploaded = false;
      
    }
       
    this.updateDataUploadState(dataUploaded)
    this.setState({loadState: false})
  }


  
  render() {
    if (this.state.loadState){
      return (
        <div className="loadingPage">
          <Loader className="loader" type="Hearts" color="#00BFFF" />
          <p class="loaderMessage">Sending Data...</p>
        </div>
        )
    }
    else if (this.state.doneSubmit && this.state.submitSuccess && !this.state.loadState){
      console.log(`doneSubmit ${this.state.doneSubmit}, submitSuccess ${this.state.submitSuccess}`)
      return (
        <div className="mainPage">
          <p><b>Done.</b> Thank you again for participating.</p>
          <Agreement></Agreement>
          <p><b>Done.</b> Thank you again for participating.</p>
        </div>
        )
    } 
    else {
      if (this.state.doneSubmit && !this.state.submitSuccess) {
        console.log(`doneSubmit ${this.state.doneSubmit}, not submitSuccess ${!this.state.submitSuccess}`)
        return (
          <div className="mainPage">
            <p><b>FAILED SUBMISSION.</b> Please contact Dennis Morozov (dennismdan@gmail.com).</p>
            <Agreement></Agreement>
            <p><b>FAILED SUBMISSION.</b> Please contact Dennis Morozov (dennismdan@gmail.com).</p>
          </div>
          )

      } else {
      return (
        <div className="mainPage">
          <h1> Body Dimensions Input Form</h1>
          <p>First, thank you for participating. The purpose for this project:</p>
            <ul>
              <li>Is to train a machine learning model to predict body dimensions from an image. </li>
              <li>The model will be trained with some known data, which is what this form is for. The form will collect two full body images, and several body dimensions.</li>
            </ul>
          <h3>1. Attach Two Images </h3>

          <p>First, take two full body pictures, 
            One from the front and one from the side. Here are a few things to consider:
            <ol>
              <li><b>Camera position:</b> the camera should be at a natural height, let's agree to keep it beweet the belly button and chest.</li>
              <li><b>Light:</b> dramatic lights can greatly affect "machine learning". Ideally, take the picture during the day, outside but in the shade.</li>
              <li><b>Body Posture:</b> relax your body, feet shoulder length apart, arms relaxed on each side. Keep long hair in a ponytail.</li>
              <li><b>Clothes:</b> Loose clothing may distort body dimensions, please wear form fitting clothes, something you wear at the gym perhaps. </li>
              <li><b>Measurement Units:</b> Measurements are in decimal inches. For example, 5in and 3/8 = 5.375 inches, so you would write 5.38 as your measurement.
              If you use a metric system, please convert to inches (1cm = 0.394in) </li>
              <li><b>Tips and Ideas:</b> Use either a tailor or craftsman measuring tape if you have it. You can also use a simple string to get the initial length, then measure it with a ruler to get length in inches. </li>
            </ol> 
          </p>
          <p>
            <b>Note, for your peace of mind:</b> <br />
            Your face will be blurred in the database, and your name will not be directly linked to your data. Review the agreement below for more inf.
          </p>
          <div className="imageSelector">
  
            <ImageInput
              imagePath={this.state.displayImageFront}
              onChange={this.handleChangeImageInputFront}
              ></ImageInput>
  
  
            <ImageInput
              imagePath={this.state.displayImageSide}
              onChange={this.handleChangeImageInputSide}
              ></ImageInput>
  
          </div>
          <h3>2. Add Body Dimensions </h3>
          <p> Now let's document body dimensions. Each dimension has an image that will guide you on how to take the measurement.</p>
          <hr></hr>
          <h1>{this.state.genderSelection}</h1>
          <GenderSelector 
                    className="userInput"
                    fieldName="Gender"
                    selectedOption={this.state.gender}
                    handleChangeGender={this.handleChangeGender}></GenderSelector>
          
          <InputBoxText 
                    className="userInput"
                    fieldName="Name (ex: Dennis Morozov)"
                    value={this.state.name}
                    handleChange={this.handleChangeName}></InputBoxText>
  
          <InputBoxInt 
                    className="userInput"
                    fieldName="Age (ex 28)"
                    value={this.state.age}
                    handleChange={this.handleChangeAge}></InputBoxInt>
          
          <Inputbox 
                    className="userInput"
                    fieldName="Height (inches ex 68)"
                    value={this.state.height}
                    handleChange={this.handleChangeHeight}></Inputbox>

          <Inputbox 
                    className="userInput"
                    fieldName="Shoulder (inches)"
                    value={this.state.shoulder}
                    handleChange={this.handleChangeShoulder}></Inputbox>
          <ImageGuide 
          alt = {this.state.genderSelection}
          src={measurementGuide[this.state.imagGuide][1]}></ImageGuide>              
  
  
          <Inputbox 
                    className="userInput"
                    fieldName="Sleeve (inches)"
                    value={this.state.sleeve}
                    handleChange={this.handleChangeSleeve}></Inputbox>
          <ImageGuide
          alt = {this.state.genderSelection}
          src={measurementGuide[this.state.imagGuide][2]}></ImageGuide>
  
  
          <Inputbox 
                    className="userInput"
                    fieldName="Neck (inches)"
                    value={this.state.neck}
                    handleChange={this.handleChangeNeck}></Inputbox>
          <ImageGuide
          alt = {this.state.genderSelection}
          src={measurementGuide[this.state.imagGuide][3]}></ImageGuide>
  
  
          <Inputbox 
                    className="userInput"
                    fieldName="Chest (inches)"
                    value={this.state.chest}
                    handleChange={this.handleChangeChest}></Inputbox>
          <ImageGuide
          alt = {this.state.genderSelection}
          src={measurementGuide[this.state.imagGuide][4]}></ImageGuide>
  
  
          <Inputbox 
                    className="userInput"
                    fieldName="Waist (inches)"
                    value={this.state.waist}
                    handleChange={this.handleChangeWaist}></Inputbox>
          <ImageGuide
          alt = {this.state.genderSelection}
          src={measurementGuide[this.state.imagGuide][5]}></ImageGuide>
  
  
          <Inputbox 
                    className="userInput"
                    fieldName="Legs (inches)"
                    value={this.state.legs}
                    handleChange={this.handleChangeLegs}></Inputbox>
          <ImageGuide
          alt = {this.state.genderSelection}
          src={measurementGuide[this.state.imagGuide][6]}></ImageGuide> <br/>
  
    
          <button type = 'submit' onClick = {this.handleSubmit}>Submit</button>
  
          <p>Finally, thank you again for participating :). </p>
      
          <Agreement></Agreement>
      </div>
      );
    }
  }

  }
}

export default App;
