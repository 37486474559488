
import React from 'react';
import '../css/Stylesheet.css'



function InputBoxText(props){
  return (
    <form onSubmit={props.handleSubmit}>
      <label>{props.fieldName}:</label><br />
        <input 
        className="textBox"
        type="text"
        value={props.value} 
        onChange={props.handleChange} />
    </form>
  );
}

export default InputBoxText;
