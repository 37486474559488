import React from "react";
import '../css/Stylesheet.css'

function ImageInput(props){
    return(
        <label>
            <img className="selfie" 
            src={props.imagePath}
            alt="body"></img>
            <input 
            className="selfie"
            type="file"
            onChange={props.onChange}/>
        </label>
    );
}

export default ImageInput